body {
  @if $type =="" {
    font-weight: 400;
    color: #000;
    @include fontUsu;
    overflow-x: hidden;
    position: relative;
    letter-spacing: 0.1em;
  }

  @if $type ==sp {
    font-size: 14px;
    line-height: 1.7;
  }

  @if $type ==pc {
    font-size: 16px;
    line-height: 1.75;
  }
}

a {
  @if $type =="" {
    color: #000000;
    text-decoration: none;
  }
}

ul,
ol {
  @if $type =="" {
    list-style-type: none;
  }
}

img {
  @if $type =="" {
    max-width: 100%;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
  }
}

* {
  @if $type =="" {
    box-sizing: border-box;
  }
}

.tel {
  @if $type ==sp {
    color: #000;
    text-decoration: underline;
  }

  @if $type ==pc {
    color: inherit !important;
    text-decoration: none !important;
    pointer-events: none;
  }
}

sup,
sub {
  @if $type =="" {
    font-size: .6em;
  }
}

.dInlineBlock {
  @if $type =="" {
    display: inline-block !important;
  }
}

.fontEn {
  @if $type =="" {
    @include fontEn;
  }
}

.gradBg {
  @if $type =="" {
    background: $grad;
    color: #fff;
  }

  @if $type ==sp {}

  @if $type ==pc {}
}

.gradTx {
  @if $type =="" {
    background: #fff;
    color: $grad;
  }

  @if $type ==sp {}

  @if $type ==pc {}
}

#globalHeader {
  @if $type =="" {
    width: 100%;
    background-color: #fff;
    position: fixed; //absolute
    top: 0;
    left: 0;
    z-index: 1000;

    // &.isUsual {
    //   animation: isUsual .2s both;
    // }


    // &.isFixed {
    //   position: fixed;
    //   animation: isFixed .2s both;
    // }
  }

  @if $type ==header_sp {}

  @if $type ==header_pc {}

  .ghInner {
    @if $type =="" {}

    @if $type ==header_sp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 70px;
      padding: 0 80px 0 20px;
      position: relative;
    }

    @if $type ==header_pc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 120px;
      padding: 20px 20px 20px 40px;
      position: relative;
    }

    // @if $type ==tb {
    //   min-height: 100px !important;
    // }

    .ghLogo {
      @if $type =="" {}

      @if $type ==header_sp {
        max-width: 218px;
      }

      @if $type ==header_pc {
        max-width: 250px;
      }

      a {
        @if $type =="" {
          display: block;
        }
      }

      img {
        @if $type =="" {
          width: 100%;
          display: block;
        }
      }
    }

    .ghSubNav {
      @if $type =="" {}

      @if $type ==header_sp {}

      @if $type ==pc {
        font-size: 14px;
      }

      >.ghSubNavList {
        @if $type =="" {}

        @if $type ==header_sp {
          display: flex;
          display: none !important;
        }

        @if $type ==header_pc {
          display: flex;
        }

        >li {
          @if $type =="" {}

          @if $type ==header_sp {}

          @if $type ==header_pc {}

          +li {
            @if $type =="" {}

            @if $type ==header_sp {
              margin-left: 16px;
            }

            @if $type ==header_pc {
              margin-left: 16px;
            }
          }

          a {
            @if $type =="" {
              background: #fff;
              border: 1px solid #000;
              font-weight: 700;
              letter-spacing: 0em;
            }

            @if $type ==header_sp {
              display: block;
            }

            @if $type ==header_pc {
              display: grid;
              place-content: center;
              width: 200px;
              height: 60px;
              border-radius: 5px;
              font-size: 14px;
              line-height: 1.42;
              text-align: center;
              transition: all .4s ease;

              &:hover {
                color: #fff;
                background: #000;
              }
            }

            &.gradBg {
              @if $type =="" {
                background: $grad;
                color: #fff;
                border: 0px;
                letter-spacing: 0.1em;
              }

              @if $type ==sp {}

              @if $type ==header_pc {

                &:hover {
                  transition: all .4s ease;
                  background: #fff;
                  color: #ff7f6c;
                  border: 1px solid #ff7f6c;
                  // background-clip: text;
                  // -webkit-background-clip: text;
                  // -webkit-text-fill-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }

  #ghMenuBtn {
    @if $type =="" {}

    @if $type ==header_sp {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #f1f1f1;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      z-index: 10000;

      >span {
        background: #000;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        transition: .2s transform, .2s opacity;
      }

      >span {
        width: 12px;
        height: 1px;

        &:nth-child(1) {
          transform: translateY(-5px);
        }

        &:nth-child(3) {
          transform: translateY(5px);
        }
      }

      &.isOpen {
        width: 36px;
        height: 36px;
        top: 17px;
        right: 17px;
        border: 0px;

        >span {
          background: #fff;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            border-radius: 50%;
            border: 1px solid #fff;
            background: transparent;
            width: 36px;
            height: 36px;
          }

          &:nth-child(3) {
            transform: rotate(-45deg);
          }
        }
      }
    }

    @if $type ==header_pc {
      display: none !important;
    }

    // @if $type ==tb {
    //   width: 100px !important;
    //   height: 100px !important;
    // }

  }

  .ghMenu {
    @if $type =="" {
      background: $gray;
    }

    @if $type ==header_sp {
      display: none;
      width: 100%;
      height: calc(100% - 60px);
      height: 100dvh;
      position: fixed;
      top: 60px;
      top: 0;
      left: 0;
      overflow: hidden;
    }

    @if $type ==header_pc {
      display: block !important;
    }

    // @if $type ==tb {
    //   top: 100px !important;
    // }

    .ghNav {
      @if $type =="" {}

      @if $type ==header_sp {
        background: #000;
        color: #fff;
        padding: 90px 30px;
        height: 100dvh;
        overflow-y: auto;

        .ghSubNavList {
          margin-top: 20px;

          >li {
            width: 100%;
            text-align: center;

            &:has(.gradBg) {
              background: #fff;
              border-radius: 5px;
            }

            &:not(:first-of-type) {
              a {
                text-decoration: underline;
                text-underline-offset: 3px;
              }
            }

            a {
              display: block;
              color: #c4c4c4;
              font-weight: 700;
              line-height: 2.5;

              &.gradBg {
                position: relative;
                font-size: 16px;
                background: $grad;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                border-radius: 5px;
                padding: 15px;
                margin-bottom: 20px;

                &:before {
                  content: "";
                  position: absolute;
                  background: url(../images/common/arrow_grad.svg) no-repeat 0 0 / contain;
                  top: 50%;
                  right: 15px;
                  transform: translate(0, -50%);
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
      }

      @if $type ==header_pc {
        max-width: calc(1280px + 80px);
        padding: 0 30px;
        margin: 0 auto;

        .ghSubNavList {
          display: none !important;
        }
      }

      >ul {
        @if $type ==header_sp {
          max-width: 450px;
          margin-inline: auto;
        }
      }


      >ul:not([class]) {
        @if $type =="" {
          margin: 0 auto;
          font-weight: bold;
        }

        @if $type ==header_sp {
          text-align: center;
        }

        @if $type ==header_pc {
          display: flex;
          height: 50px;
        }

        >li {
          @if $type =="" {}

          @if $type ==header_sp {}

          @if $type ==header_pc {
            flex: 1;
            position: relative;

            +li:before,
            &:last-child:after {
              content: "";
              display: block;
              width: 1px;
              height: 14px;
              background: #747474;
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
            }
          }

          >a {
            @if $type =="" {}

            @if $type ==header_sp {
              display: block;
              padding: 12px 20px;
              color: #fff;
              font-size: 16px;
            }

            @if $type ==header_pc {
              font-size: 14px;
              line-height: 1.7;
              letter-spacing: 0.05em;
              display: block;
              height: 50px;
              line-height: 50px;
              // padding-inline: 10px;
              padding-inline: 8px;
              text-align: center;
              transition: all .2s;

              &:hover {
                opacity: .5;
              }
            }
          }
        }

      }
    }
  }
}


main {
  @if $type =="" {
    display: block;
    background: #fff;
    overflow: hidden;
  }

  @if $type ==header_sp {
    padding-top: 70px;
  }

  @if $type ==header_pc {
    padding-top: 170px;
  }

  // @if $type ==tb {
  //   padding-top: 100px !important;
  // }

}

#globalFooter {
  @if $type =="" {
    color: #fff;
    background: #000;
    position: relative;
  }

  @if $type ==header_sp {}

  @if $type ==header_pc {}

  #pageTop {
    @if $type =="" {
      display: block;
      border-radius: 50%;
      background: url(../images/common/arrow_gray.svg) no-repeat 0 0 / contain;
      text-indent: -9999px;
      z-index: 100;
      position: fixed;
      cursor: pointer;
    }

    @if $type ==header_sp {
      width: 40px;
      height: 40px;
      right: 15px;
      bottom: 15px;
    }

    @if $type ==header_pc {
      width: 60px;
      height: 60px;
      right: 50px;
      bottom: 20px;
      transition: .2s transform;

      &:hover {
        transform: translateY(-15px);
      }
    }
  }

  .gfInner {
    @if $type =="" {
      margin-inline: auto;
    }

    @if $type ==header_sp {
      width: calc(100% - 60px);
      padding-block: 50px;
    }

    @if $type ==header_pc {
      display: flex;
      @include maxPC;
      padding-block: 100px;
    }


    .gfInfo {
      @if $type =="" {}

      @if $type ==header_sp {
        max-width: 320px;
        margin-inline: auto;
      }

      @if $type ==header_pc {
        width: 390px;
        margin-right: auto;
      }



      .gfLogo {
        @if $type =="" {}

        @if $type ==header_sp {
          max-width: 320px;
          margin-top: 16px;
        }

        @if $type ==header_pc {
          margin-top: 16px;
        }

        a {
          @if $type ==header_pc {
            display: block;

            img {
              transition: all .2s ease;
            }

            &:hover {
              img {
                opacity: .5;
              }
            }
          }
        }
      }

      .gfLocation {
        @if $type =="" {
          font-weight: 700;
          color: #c4c4c4;
          margin-block: 10px 24px;
        }

        @if $type ==header_sp {
          text-align: center;
        }

        @if $type ==header_pc {
          font-size: 14px;
        }
      }

      dl.wrap {
        @if $type =="" {
          color: #c4c4c4;
          display: flex;
        }

        @if $type ==header_sp {
          font-size: 12px;
          gap: 10px;
        }

        @if $type ==header_pc {
          font-size: 14px;
          gap: 12px;
        }

        dt {
          @if $type =="" {
            font-weight: 700;
          }

          @if $type ==header_sp {
            width: 56px;
          }

          @if $type ==header_pc {
            width: 65px;
          }
        }

        dd {
          @if $type =="" {}

          @if $type ==header_sp {
            width: calc(100% - 56px - 10px);
          }

          @if $type ==header_pc {
            width: calc(100% - 65px - 12px);
            letter-spacing: 0.05em;
          }

          .sp0 {
            @if $type ==pc {
              letter-spacing: 0;
            }
          }
        }
      }
    }

    .contactBtn {
      @if $type =="" {
        font-weight: 700;
      }

      @if $type ==header_sp {
        margin-block: 30px;
      }

      @if $type ==header_pc {
        margin-top: 30px;
      }

      a {
        @if $type =="" {
          position: relative;
          display: block;
          background: #fff;
          border-radius: 5px;
          text-align: center;
          padding-inline: 22px;
          height: 70px;
          font-size: 16px;
          line-height: 70px;
          padding-inline: 25px;
          border: 1px solid #fff;
        }

        @if $type ==header_sp {}

        @if $type ==header_pc {
          transition: all .3s ease;
        }

        &:before {
          @if $type =="" {
            content: "";
            position: absolute;
            background: url(../images/common/arrow_black.svg) no-repeat 0 0 / contain;
            top: 50%;
            transform: translate(0, -50%);
            width: 20px;
            height: 20px;
          }

          @if $type ==header_sp {
            right: 16px;
          }

          @if $type ==header_pc {
            right: 22px;
          }
        }

        &:hover {
          color: #fff;
          background: #000;

          &:before {
            @if $type ==header_pc {
              background: url(../images/common/arrow_white.svg) no-repeat 0 0 / contain;
            }
          }
        }
      }
    }

    .sns_icons {
      @if $type =="" {
        display: flex;
      }

      &.sp {
        @if $type ==header_sp {
          justify-content: center;
          margin-top: 30px;
          margin-left: 12px;
        }

        @if $type ==header_pc {
          display: none !important;
        }
      }


      &.pc {
        @if $type ==header_sp {
          display: none !important;
        }

        @if $type ==header_pc {
          justify-content: flex-end;
        }
      }

      li {
        @if $type =="" {
          height: 40px;
        }

        @if $type ==header_sp {
          width: calc(100% / 3);
        }

        @if $type ==header_pc {
          width: 80px;
        }

        +li {
          @if $type =="" {
            position: relative;
          }

          @if $type ==header_sp {}

          @if $type ==header_pc {}

          &:before {
            @if $type =="" {
              content: "";
              position: absolute;
              background: #6A6A6A;
              width: 1px;
              height: 100%;
            }

            @if $type ==header_sp {}

            @if $type ==header_pc {}
          }
        }

        a {
          @if $type =="" {
            display: grid;
            place-content: center;
            width: 100%;
            height: 40px;
          }

          @if $type ==header_sp {}

          @if $type ==header_pc {}

          img {
            @if $type ==header_pc {
              transition: all .2s ease;
            }

            &.icon_facebook {
              @if $type =="" {
                width: 18px;
                height: 34px;
              }
            }

            &.icon_x {
              @if $type =="" {
                width: 26px;
                height: 27px;
              }
            }

            &.icon_instagram {
              @if $type =="" {
                width: 36px;
                height: 36px;
              }
            }

            &.icon_youtube {
              @if $type =="" {
                width: 35px;
                height: 24px;
              }
            }
          }

          &:hover {
            img {
              @if $type ==header_pc {
                opacity: .5;
              }
            }
          }
        }

      }

    }

    .gfSitemap {
      @if $type =="" {
        position: relative;
      }

      @if $type ==header_sp {
        margin-top: 40px;
      }

      @if $type ==header_pc {
        width: calc(100% - 50px - 390px);
      }

      ul.sitemapTop {
        @if $type =="" {
          position: relative;
          display: flex;
          flex-wrap: wrap;
        }

        @if $type ==header_sp {
          padding-block: 23px;
          max-width: 274px;
          margin-inline: auto;
          column-gap: 10px;
          row-gap: 0;
        }

        @if $type ==header_pc {
          margin-block: 40px 28px;
          padding-bottom: 28px;
          column-gap: 10px;
          row-gap: 10px;
          justify-content: flex-end;
        }

        &:before,
        &:after {
          @if $type =="" {
            content: "";
            position: absolute;
            background: #3a3a3a;
            width: 100%;
            height: 1px;
          }

          @if $type ==header_sp {}

          @if $type ==header_pc {}
        }

        &:before {
          @if $type =="" {}

          @if $type ==header_sp {
            top: 0;
          }

          @if $type ==header_pc {
            display: none;
          }
        }

        &:after {
          @if $type =="" {
            bottom: 0;
          }

          @if $type ==header_sp {}

          @if $type ==header_pc {}
        }

        li {
          @if $type =="" {}

          @if $type ==header_sp {
            width: calc(50% - 5px);
          }

          @if $type ==header_pc {
            width: calc((100% - 30px) / 4);
          }


          a {
            @if $type =="" {
              color: #c4c4c4;
              position: relative;
              display: inline-block;
              padding-left: 24px;
              padding-block: 7px;
              font-size: 14px;
              line-height: 1.3;
            }

            @if $type ==header_sp {}

            @if $type ==header_pc {
              transition: all .3s;
            }

            &:hover {
              @if $type ==header_pc {
                opacity: .5;
              }
            }

            &:before {
              @if $type =="" {
                content: "";
                position: absolute;
                background: url(../images/common/arrow_white.svg) no-repeat 0 0 / contain;
                width: 15px;
                height: 15px;
                left: 0;
                top: 10px;
              }

              @if $type ==header_sp {}

              @if $type ==header_pc {}
            }
          }
        }
      }

      ul.sitemapBottom {
        @if $type =="" {}

        @if $type ==header_sp {
          text-align: center;
          padding-block: 26px;
        }

        @if $type ==header_pc {
          display: flex;
        }


        li {
          @if $type =="" {}

          @if $type ==header_sp {}

          @if $type ==header_pc {
            padding-right: 24px;
          }

          +li {
            @if $type =="" {
              position: relative;
            }

            @if $type ==header_sp {}

            @if $type ==header_pc {
              padding-left: 24px;
              padding-left: min(1.71vw, 24px);
            }

            &:before {
              @if $type =="" {}

              @if $type ==header_sp {}

              @if $type ==header_pc {
                content: "";
                position: absolute;
                background: #c4c4c4;
                width: 1px;
                height: 100%;
                top: 0;
                left: 0;
              }
            }
          }

          &:last-of-type {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
              padding-right: 0;
            }
          }

          a {
            @if $type =="" {
              color: #c4c4c4;
              position: relative;
              display: inline-block;
              padding-block: 4px;
            }

            @if $type ==header_sp {
              font-size: 12px;
            }

            @if $type ==header_pc {
              font-size: 14px;
              transition: all .3s;
            }

            &:hover {
              @if $type ==header_pc {
                opacity: .5;
              }
            }
          }
        }
      }




      .gfCopyright {
        @if $type =="" {
          color: #c4c4c4;
          @include fontEn;
          font-size: 12px;
        }

        @if $type ==header_sp {
          text-align: center;
        }

        @if $type ==header_pc {
          margin-top: 20px;
        }

        small {
          @if $type =="" {
            font-size: 12px;
          }

          @if $type ==header_sp {}

          @if $type ==header_pc {}
        }
      }


    }
  }
}

/* ココから下には記入禁止 */
.forPC {
  @if $type ==sp {
    display: none !important;
  }
}

.forSP {
  @if $type ==pc {
    display: none !important;
  }
}