@charset "UTF-8";
/*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  vertical-align: top;
}

table {
  border-collapse: collapse;
}

button, input, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, html [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0;
}

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/* --------------- variables --------------------- */
/* Break Point */
/* Color */
body {
  font-weight: 400;
  color: #000;
  font-family: "Noto Sans JP", sans-serif;
  overflow-x: hidden;
  position: relative;
  letter-spacing: 0.1em;
}

a {
  color: #000000;
  text-decoration: none;
}

ul,
ol {
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

* {
  box-sizing: border-box;
}

sup,
sub {
  font-size: 0.6em;
}

.dInlineBlock {
  display: inline-block !important;
}

.fontEn {
  font-family: "Inter", sans-serif;
}

.gradBg {
  background: linear-gradient(90deg, rgb(255, 110, 110) 0%, rgb(255, 172, 103) 100%);
  color: #fff;
}

.gradTx {
  background: #fff;
  color: linear-gradient(90deg, rgb(255, 110, 110) 0%, rgb(255, 172, 103) 100%);
}

#globalHeader {
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
#globalHeader .ghInner .ghLogo a {
  display: block;
}
#globalHeader .ghInner .ghLogo img {
  width: 100%;
  display: block;
}
#globalHeader .ghInner .ghSubNav > .ghSubNavList > li a {
  background: #fff;
  border: 1px solid #000;
  font-weight: 700;
  letter-spacing: 0em;
}
#globalHeader .ghInner .ghSubNav > .ghSubNavList > li a.gradBg {
  background: linear-gradient(90deg, rgb(255, 110, 110) 0%, rgb(255, 172, 103) 100%);
  color: #fff;
  border: 0px;
  letter-spacing: 0.1em;
}
#globalHeader .ghMenu {
  background: #f8f8f8;
}
#globalHeader .ghMenu .ghNav > ul:not([class]) {
  margin: 0 auto;
  font-weight: bold;
}
main {
  display: block;
  background: #fff;
  overflow: hidden;
}

#globalFooter {
  color: #fff;
  background: #000;
  position: relative;
}
#globalFooter #pageTop {
  display: block;
  border-radius: 50%;
  background: url(../images/common/arrow_gray.svg) no-repeat 0 0/contain;
  text-indent: -9999px;
  z-index: 100;
  position: fixed;
  cursor: pointer;
}
#globalFooter .gfInner {
  margin-inline: auto;
}
#globalFooter .gfInner .gfInfo .gfLocation {
  font-weight: 700;
  color: #c4c4c4;
  margin-block: 10px 24px;
}
#globalFooter .gfInner .gfInfo dl.wrap {
  color: #c4c4c4;
  display: flex;
}
#globalFooter .gfInner .gfInfo dl.wrap dt {
  font-weight: 700;
}
#globalFooter .gfInner .contactBtn {
  font-weight: 700;
}
#globalFooter .gfInner .contactBtn a {
  position: relative;
  display: block;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  padding-inline: 22px;
  height: 70px;
  font-size: 16px;
  line-height: 70px;
  padding-inline: 25px;
  border: 1px solid #fff;
}
#globalFooter .gfInner .contactBtn a:before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_black.svg) no-repeat 0 0/contain;
  top: 50%;
  transform: translate(0, -50%);
  width: 20px;
  height: 20px;
}
#globalFooter .gfInner .contactBtn a:hover {
  color: #fff;
  background: #000;
}
#globalFooter .gfInner .sns_icons {
  display: flex;
}
#globalFooter .gfInner .sns_icons li {
  height: 40px;
}
#globalFooter .gfInner .sns_icons li + li {
  position: relative;
}
#globalFooter .gfInner .sns_icons li + li:before {
  content: "";
  position: absolute;
  background: #6A6A6A;
  width: 1px;
  height: 100%;
}
#globalFooter .gfInner .sns_icons li a {
  display: grid;
  place-content: center;
  width: 100%;
  height: 40px;
}
#globalFooter .gfInner .sns_icons li a img.icon_facebook {
  width: 18px;
  height: 34px;
}
#globalFooter .gfInner .sns_icons li a img.icon_x {
  width: 26px;
  height: 27px;
}
#globalFooter .gfInner .sns_icons li a img.icon_instagram {
  width: 36px;
  height: 36px;
}
#globalFooter .gfInner .sns_icons li a img.icon_youtube {
  width: 35px;
  height: 24px;
}
#globalFooter .gfInner .gfSitemap {
  position: relative;
}
#globalFooter .gfInner .gfSitemap ul.sitemapTop {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
#globalFooter .gfInner .gfSitemap ul.sitemapTop:before, #globalFooter .gfInner .gfSitemap ul.sitemapTop:after {
  content: "";
  position: absolute;
  background: #3a3a3a;
  width: 100%;
  height: 1px;
}
#globalFooter .gfInner .gfSitemap ul.sitemapTop:after {
  bottom: 0;
}
#globalFooter .gfInner .gfSitemap ul.sitemapTop li a {
  color: #c4c4c4;
  position: relative;
  display: inline-block;
  padding-left: 24px;
  padding-block: 7px;
  font-size: 14px;
  line-height: 1.3;
}
#globalFooter .gfInner .gfSitemap ul.sitemapTop li a:before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_white.svg) no-repeat 0 0/contain;
  width: 15px;
  height: 15px;
  left: 0;
  top: 10px;
}
#globalFooter .gfInner .gfSitemap ul.sitemapBottom li + li {
  position: relative;
}
#globalFooter .gfInner .gfSitemap ul.sitemapBottom li a {
  color: #c4c4c4;
  position: relative;
  display: inline-block;
  padding-block: 4px;
}
#globalFooter .gfInner .gfSitemap .gfCopyright {
  color: #c4c4c4;
  font-family: "Inter", sans-serif;
  font-size: 12px;
}
#globalFooter .gfInner .gfSitemap .gfCopyright small {
  font-size: 12px;
}

/* ココから下には記入禁止 */
@media screen and (max-width: 1023px) {
  #globalHeader .ghInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;
    padding: 0 80px 0 20px;
    position: relative;
  }
  #globalHeader .ghInner .ghLogo {
    max-width: 218px;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList {
    display: flex;
    display: none !important;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList > li + li {
    margin-left: 16px;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList > li a {
    display: block;
  }
  #globalHeader #ghMenuBtn {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 10000;
  }
  #globalHeader #ghMenuBtn > span {
    background: #000;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    transition: 0.2s transform, 0.2s opacity;
  }
  #globalHeader #ghMenuBtn > span {
    width: 12px;
    height: 1px;
  }
  #globalHeader #ghMenuBtn > span:nth-child(1) {
    transform: translateY(-5px);
  }
  #globalHeader #ghMenuBtn > span:nth-child(3) {
    transform: translateY(5px);
  }
  #globalHeader #ghMenuBtn.isOpen {
    width: 36px;
    height: 36px;
    top: 17px;
    right: 17px;
    border: 0px;
  }
  #globalHeader #ghMenuBtn.isOpen > span {
    background: #fff;
  }
  #globalHeader #ghMenuBtn.isOpen > span:nth-child(1) {
    transform: rotate(45deg);
  }
  #globalHeader #ghMenuBtn.isOpen > span:nth-child(2) {
    border-radius: 50%;
    border: 1px solid #fff;
    background: transparent;
    width: 36px;
    height: 36px;
  }
  #globalHeader #ghMenuBtn.isOpen > span:nth-child(3) {
    transform: rotate(-45deg);
  }
  #globalHeader .ghMenu {
    display: none;
    width: 100%;
    height: calc(100% - 60px);
    height: 100dvh;
    position: fixed;
    top: 60px;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  #globalHeader .ghMenu .ghNav {
    background: #000;
    color: #fff;
    padding: 90px 30px;
    height: 100dvh;
    overflow-y: auto;
  }
  #globalHeader .ghMenu .ghNav .ghSubNavList {
    margin-top: 20px;
  }
  #globalHeader .ghMenu .ghNav .ghSubNavList > li {
    width: 100%;
    text-align: center;
  }
  #globalHeader .ghMenu .ghNav .ghSubNavList > li:has(.gradBg) {
    background: #fff;
    border-radius: 5px;
  }
  #globalHeader .ghMenu .ghNav .ghSubNavList > li:not(:first-of-type) a {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
  #globalHeader .ghMenu .ghNav .ghSubNavList > li a {
    display: block;
    color: #c4c4c4;
    font-weight: 700;
    line-height: 2.5;
  }
  #globalHeader .ghMenu .ghNav .ghSubNavList > li a.gradBg {
    position: relative;
    font-size: 16px;
    background: linear-gradient(90deg, rgb(255, 110, 110) 0%, rgb(255, 172, 103) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
  }
  #globalHeader .ghMenu .ghNav .ghSubNavList > li a.gradBg:before {
    content: "";
    position: absolute;
    background: url(../images/common/arrow_grad.svg) no-repeat 0 0/contain;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
    width: 20px;
    height: 20px;
  }
  #globalHeader .ghMenu .ghNav > ul {
    max-width: 450px;
    margin-inline: auto;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) {
    text-align: center;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) > li > a {
    display: block;
    padding: 12px 20px;
    color: #fff;
    font-size: 16px;
  }
  main {
    padding-top: 70px;
  }
  #globalFooter #pageTop {
    width: 40px;
    height: 40px;
    right: 15px;
    bottom: 15px;
  }
  #globalFooter .gfInner {
    width: calc(100% - 60px);
    padding-block: 50px;
  }
  #globalFooter .gfInner .gfInfo {
    max-width: 320px;
    margin-inline: auto;
  }
  #globalFooter .gfInner .gfInfo .gfLogo {
    max-width: 320px;
    margin-top: 16px;
  }
  #globalFooter .gfInner .gfInfo .gfLocation {
    text-align: center;
  }
  #globalFooter .gfInner .gfInfo dl.wrap {
    font-size: 12px;
    gap: 10px;
  }
  #globalFooter .gfInner .gfInfo dl.wrap dt {
    width: 56px;
  }
  #globalFooter .gfInner .gfInfo dl.wrap dd {
    width: calc(100% - 56px - 10px);
  }
  #globalFooter .gfInner .contactBtn {
    margin-block: 30px;
  }
  #globalFooter .gfInner .contactBtn a:before {
    right: 16px;
  }
  #globalFooter .gfInner .contactBtn a:hover {
    color: #fff;
    background: #000;
  }
  #globalFooter .gfInner .sns_icons.sp {
    justify-content: center;
    margin-top: 30px;
    margin-left: 12px;
  }
  #globalFooter .gfInner .sns_icons.pc {
    display: none !important;
  }
  #globalFooter .gfInner .sns_icons li {
    width: 33.3333333333%;
  }
  #globalFooter .gfInner .gfSitemap {
    margin-top: 40px;
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapTop {
    padding-block: 23px;
    max-width: 274px;
    margin-inline: auto;
    column-gap: 10px;
    row-gap: 0;
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapTop:before {
    top: 0;
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapTop li {
    width: calc(50% - 5px);
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapBottom {
    text-align: center;
    padding-block: 26px;
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapBottom li a {
    font-size: 12px;
  }
  #globalFooter .gfInner .gfSitemap .gfCopyright {
    text-align: center;
  }
  /* ココから下には記入禁止 */
}
@media screen and (min-width: 1024px) {
  #globalHeader .ghInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 120px;
    padding: 20px 20px 20px 40px;
    position: relative;
  }
  #globalHeader .ghInner .ghLogo {
    max-width: 250px;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList {
    display: flex;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList > li + li {
    margin-left: 16px;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList > li a {
    display: grid;
    place-content: center;
    width: 200px;
    height: 60px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 1.42;
    text-align: center;
    transition: all 0.4s ease;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList > li a:hover {
    color: #fff;
    background: #000;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList > li a.gradBg:hover {
    transition: all 0.4s ease;
    background: #fff;
    color: #ff7f6c;
    border: 1px solid #ff7f6c;
  }
  #globalHeader #ghMenuBtn {
    display: none !important;
  }
  #globalHeader .ghMenu {
    display: block !important;
  }
  #globalHeader .ghMenu .ghNav {
    max-width: 1360px;
    padding: 0 30px;
    margin: 0 auto;
  }
  #globalHeader .ghMenu .ghNav .ghSubNavList {
    display: none !important;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) {
    display: flex;
    height: 50px;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) > li {
    flex: 1;
    position: relative;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) > li + li:before, #globalHeader .ghMenu .ghNav > ul:not([class]) > li:last-child:after {
    content: "";
    display: block;
    width: 1px;
    height: 14px;
    background: #747474;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) > li > a {
    font-size: 14px;
    line-height: 1.7;
    letter-spacing: 0.05em;
    display: block;
    height: 50px;
    line-height: 50px;
    padding-inline: 8px;
    text-align: center;
    transition: all 0.2s;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) > li > a:hover {
    opacity: 0.5;
  }
  main {
    padding-top: 170px;
  }
  #globalFooter #pageTop {
    width: 60px;
    height: 60px;
    right: 50px;
    bottom: 20px;
    transition: 0.2s transform;
  }
  #globalFooter #pageTop:hover {
    transform: translateY(-15px);
  }
  #globalFooter .gfInner {
    display: flex;
    max-width: 1000px;
    width: calc(100% - 120px);
    margin: 0 auto;
    padding-block: 100px;
  }
  #globalFooter .gfInner .gfInfo {
    width: 390px;
    margin-right: auto;
  }
  #globalFooter .gfInner .gfInfo .gfLogo {
    margin-top: 16px;
  }
  #globalFooter .gfInner .gfInfo .gfLogo a {
    display: block;
  }
  #globalFooter .gfInner .gfInfo .gfLogo a img {
    transition: all 0.2s ease;
  }
  #globalFooter .gfInner .gfInfo .gfLogo a:hover img {
    opacity: 0.5;
  }
  #globalFooter .gfInner .gfInfo .gfLocation {
    font-size: 14px;
  }
  #globalFooter .gfInner .gfInfo dl.wrap {
    font-size: 14px;
    gap: 12px;
  }
  #globalFooter .gfInner .gfInfo dl.wrap dt {
    width: 65px;
  }
  #globalFooter .gfInner .gfInfo dl.wrap dd {
    width: calc(100% - 65px - 12px);
    letter-spacing: 0.05em;
  }
  #globalFooter .gfInner .contactBtn {
    margin-top: 30px;
  }
  #globalFooter .gfInner .contactBtn a {
    transition: all 0.3s ease;
  }
  #globalFooter .gfInner .contactBtn a:before {
    right: 22px;
  }
  #globalFooter .gfInner .contactBtn a:hover {
    color: #fff;
    background: #000;
  }
  #globalFooter .gfInner .contactBtn a:hover:before {
    background: url(../images/common/arrow_white.svg) no-repeat 0 0/contain;
  }
  #globalFooter .gfInner .sns_icons.sp {
    display: none !important;
  }
  #globalFooter .gfInner .sns_icons.pc {
    justify-content: flex-end;
  }
  #globalFooter .gfInner .sns_icons li {
    width: 80px;
  }
  #globalFooter .gfInner .sns_icons li a img {
    transition: all 0.2s ease;
  }
  #globalFooter .gfInner .sns_icons li a:hover img {
    opacity: 0.5;
  }
  #globalFooter .gfInner .gfSitemap {
    width: calc(100% - 50px - 390px);
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapTop {
    margin-block: 40px 28px;
    padding-bottom: 28px;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: flex-end;
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapTop:before {
    display: none;
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapTop li {
    width: calc((100% - 30px) / 4);
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapTop li a {
    transition: all 0.3s;
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapTop li a:hover {
    opacity: 0.5;
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapBottom {
    display: flex;
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapBottom li {
    padding-right: 24px;
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapBottom li + li {
    padding-left: 24px;
    padding-left: min(1.71vw, 24px);
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapBottom li + li:before {
    content: "";
    position: absolute;
    background: #c4c4c4;
    width: 1px;
    height: 100%;
    top: 0;
    left: 0;
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapBottom li a {
    font-size: 14px;
    transition: all 0.3s;
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapBottom li a:hover {
    opacity: 0.5;
  }
  #globalFooter .gfInner .gfSitemap .gfCopyright {
    margin-top: 20px;
  }
  /* ココから下には記入禁止 */
}
@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
    line-height: 1.7;
  }
  .tel {
    color: #000;
    text-decoration: underline;
  }
  #globalFooter .gfInner .contactBtn a:hover {
    color: #fff;
    background: #000;
  }
  /* ココから下には記入禁止 */
  .forPC {
    display: none !important;
  }
}
@media print, screen and (min-width: 769px) {
  body {
    font-size: 16px;
    line-height: 1.75;
  }
  .tel {
    color: inherit !important;
    text-decoration: none !important;
    pointer-events: none;
  }
  #globalHeader .ghInner .ghSubNav {
    font-size: 14px;
  }
  #globalFooter .gfInner .gfInfo dl.wrap dd .sp0 {
    letter-spacing: 0;
  }
  #globalFooter .gfInner .contactBtn a:hover {
    color: #fff;
    background: #000;
  }
  #globalFooter .gfInner .gfSitemap ul.sitemapBottom li:last-of-type {
    padding-right: 0;
  }
  /* ココから下には記入禁止 */
  .forSP {
    display: none !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  #globalFooter .gfInner .contactBtn a:hover {
    color: #fff;
    background: #000;
  }
  /* ココから下には記入禁止 */
}