/* --------------- variables --------------------- */

/* Break Point */
$breakpoint_sp		: 768px;
$breakpoint_tb		: 1023px;
$breakpoint_pc		: $breakpoint_sp + 1;


/* Color */
$grad: linear-gradient(90deg, rgba(255, 110, 110, 1) 0%, rgba(255, 172, 103, 1) 100%);
$text_gray: #747474;
$gray: #f8f8f8;
$pink: #FF6E6E;
$yellow: #FFB800;
$green: #55E19E;
$momo: #FF80D9;
$blue: #5585FF;


@mixin maxPC {
    max-width: 1000px;
    width: calc(100% - 120px);
    margin: 0 auto;
}

@mixin fontUsu {
    font-family: 'Noto Sans JP', sans-serif;
}

@mixin fontEn {
    font-family: "Inter", sans-serif;
}

@mixin fontJa {
    font-family: "Zen Kaku Gothic New", sans-serif;
}